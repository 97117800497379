import BaseSelect from '@/components/common/base-select/base-select'
import IsDefault from '../components/is-default.vue'
import { idCardReg, phoneReg } from '@/config/reg-config'

// 手机号验证
function validatorPhone (r, v, c) {
  if (!v) {
    c(new Error('请输入手机号'))
  } else if (!phoneReg.test(v)) {
    c(new Error('请输入正确手机号'))
  } else {
    c()
  }
}
// 邮箱验证
// function validatorEmail (r, v, c) {
//   if (!v) {
//     c(new Error('请输入邮箱'))
//   } else if (!emailReg.test(v)) {
//     c(new Error('请输入正确邮箱'))
//   } else {
//     c()
//   }
// }
// 身份证验证
function validatorIDCard (r, v, c) {
  if (!v) {
    c(new Error('请输入身份证'))
  } else if (!idCardReg.test(v)) {
    c(new Error('请输入正确身份证'))
  } else {
    c()
  }
}
function validatorAcountNo (r, v, c) {
  if (!v) {
    c(new Error('请输入账户'))
  } else if (!(v.length > 7 && v.length < 31)) {
    c(new Error('请输入8-30位账户'))
  } else {
    c()
  }
}
// 企业信息
export const enterpriseConfig = (content) => {
  return [
    {
      label: '申请企业',
      prop: 'enterpriseName',
      rules: [{ required: true, message: '请输入', trigger: 'blur' }],
      attrs: {
        disabled: true
      }
    },
    {
      label: '企业证件类型',
      prop: 'creditName',
      rules: [{ required: true, message: '请输入', trigger: 'blur' }],
      attrs: {
        disabled: true
      },
      isHidden: content.sealAdminType === '2'
    },
    {
      label: '企业证件号码',
      prop: 'creditCode',
      rules: [{ required: true, message: '请输入', trigger: 'blur' }],
      attrs: {
        disabled: true
      },
      isHidden: content.sealAdminType === '2'
    }
  ]
}
// 账户信息
export const accountConfig = (content) => {
  return [
    {
      label: '户名',
      prop: 'account',
      rules: [{ required: true, message: '请输入户名', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        disabled: content.changeState === 'BGGYS' || content.changeState === 'YDRZ' // 如果是供应商编辑 或者 远端状态为2的时候， 禁用输入，带出数据
      }
    },
    {
      label: '银行',
      prop: 'bankId',
      rules: [{ required: true, message: '请输入银行', trigger: 'change' }],
      attrs: {
        placeholder: '请输入银行',
        options: content.bankList,
        selectedField: ['bankId', 'bankName'],
        filterable: true,
        max: 1000,
        disabled: content.changeState === 'BGGYS' || content.changeState === 'YDRZ'
      },
      tag: BaseSelect
    },
    {
      label: '卡号',
      prop: 'accountNo',
      rules: [{ required: true, validator: validatorAcountNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        disabled: content.changeState === 'BGGYS' || content.changeState === 'YDRZ'
      },
      on: {
        input: (data) => {
          content.changeAccountNo(data)
        }
      }
    }
  ]
}
// 签章人信息
export const signerConfig = (content) => {
  // const emileFlage = content.signerQuery.phone && content.signerQuery.email ? true : !content.signerQuery.phone
  // const phoneFalge = content.signerQuery.phone && content.signerQuery.email ? true : !content.signerQuery.email
  return [
    {
      label: '姓名',
      prop: 'personId',
      rules: [{ required: true, message: '请选择签章人', trigger: 'change' }],
      attrs: {
        placeholder: '请选择签章人',
        options: content.personnelList,
        filterable: true,
        disabled: content.changeState === 'BGGR' || content.changeState === 'BJGR',
        selectedField: ['keyId', 'personnerName']
      },
      tag: BaseSelect,
      on: {
        change (data) {
          content.changeSigner(data)
        }
      }
    },
    {
      label: '身份证号',
      prop: 'idCard',
      rules: [{ required: true, message: '请输入身份证号', trigger: 'change' }],
      attrs: {
        placeholder: '请输入身份证号',
        disabled: true
      }

    },
    {
      label: '手机号',
      prop: 'phone',
      rules: [{ required: true, validator: validatorPhone, trigger: 'change' }],
      attrs: {
        placeholder: '请输入手机号',
        maxlength: '11'
      },
      on: {
        input: (data) => {
          if (data) {
            content.clearPhoneValidate(data)
          }
        }
      }
    },

    // {
    //   label: '邮箱',
    //   prop: 'email',
    //   rules: [emileFlage ? { required: true, validator: validatorEmail, trigger: 'change' } : ''],
    //   attrs: {
    //     placeholder: '请输入,手机号和邮箱必填一个',
    //     maxlength: '30'
    //     // disabled: content.successSubmitDisabled
    //   },
    //   on: {
    //     input: (data) => {
    //       if (data) {
    //         content.clearPhoneValidate()
    //       }
    //     }
    //   }
    // },
    {
      label: '',
      prop: 'isDefault',
      attrs: {
        'label-position': 'left',
        'true-label': '1',
        'false-label': '0',
        disabled: content.authStatus === '0' // 认证状态为 0 第一次进入认证默认选中且禁用
      },
      isHidden: content.sealAdminType === '2',
      tag: IsDefault
    }
  ]
}

// 新增签章人
export const addSignerConfig = (content) => {
  const span = 8
  return [
    {
      label: '姓名',
      prop: 'personnerName',
      rules: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        disabled: content.disabled,
        maxlength: '20'
      },
      span
    },
    {
      label: '身份证号',
      prop: 'idCard',
      rules: [{ required: true, validator: validatorIDCard, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        disabled: content.disabled,
        maxlength: '18'
      },
      span
    },
    {
      label: '手机号',
      prop: 'mobilePhoneNo',
      rules: [{ required: true, validator: validatorPhone, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        disabled: content.disabled,
        maxlength: '11'
      },
      span
    },
    {
      label: '职位',
      prop: 'dutiesName',
      rules: [{ required: true, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        disabled: content.disabled,
        maxlength: '20'
      },
      span
    },
    {
      label: '人员类别',
      prop: 'newPersonnelCategory',
      rules: [{ required: true, message: '请选择入人员类别', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        options: content.personTypeOptions,
        selectedField: ['dictId', 'dictName'],
        disabled: content.disabled,
        multiple: true
      },
      on: {
        change (data) {
          if (data && data.length > 0) {
            content.clearPersonnelCategory(data)
          }
        }
      },
      tag: BaseSelect,
      span
    }
  ]
}
