  // 新增议弹出框
<template>
     <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      :title="title"
      class="addSginer"
    >
    <div v-if="disabled" class='tips' ><i class="el-icon-warning" />请再次确认人员信息的正确性</div>
    <div v-else class='tips' style="height:19px"></div>
    <base-form
     :componentList="addSignerFrom"
      :showBtns="false"
      :formAttrs="{
        model: queryParas,
      }"
      class="formStyle"
      ref="formData"
    ></base-form>
    <template slot="footer" >
     <base-button label="提交" v-if="!disabled" @click="changeAdd"/>
     <base-button label="提交" v-else @click="submitAddSigner"/>
     <base-button label="取消"  @click="cancel" type="default"/>
    </template>
    </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form'
import { addSignerConfig } from '../utils/add-authentication-config'
import { getDictLists } from '@/filters/fromDict'
import { personnelApi } from '@/api/companyApi'

export default {
  props: {
    visible: Boolean,
    enterpriseId: String
  },
  data () {
    return {
      title: '新增签章人',
      queryParas: {}, // 表数据
      disabled: false // 禁用按钮
    }
  },
  components: { BaseDialog, BaseButton, BaseForm },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
        if (!val) {
          this.$nextTick(() => {
            this.title = '新增签章人'
            this.disabled = false
            this.$refs.formData.resetFields()
          })
        }
      }
    },
    addSignerFrom () {
      return addSignerConfig(this)
    },
    // 人员类别
    personTypeOptions () {
      return getDictLists('PERSONNEL_TYPE')
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.queryParas = {}
      }
    }
  },
  methods: {
    // 第一次提交
    changeAdd () {
      this.title = '提示'
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.disabled = true
        }
      })
    },
    // 第二此提交
    submitAddSigner () {
      this.title = '新增签章人'
      this.queryParas.personnelCategory = this.queryParas.newPersonnelCategory.join(',')
      personnelApi.addSigner({ enterpriseId: this.enterpriseId, ...this.queryParas }).then(res => {
        if (res.data) {
          this.success('新增成功')
          this.$parent.getGysPersonnelList()
          this.visibleState = false
          this.disabled = false
        }
      }).catch(err => {
        console.log(err, '进来了')
      })
    },
    // 取消
    cancel () {
      if (this.disabled) {
        this.title = '新增签章人'
        this.disabled = false
      } else {
        this.visibleState = false
      }
    },
    // 清除校验
    clearPersonnelCategory (data) {
      console.log(data)
      this.$refs.formData.clearValidate('personnelCategory')
    }

  }
}
</script>
<style lang="scss" scoped>
  .addSginer{
    margin-top: 200px;
    height: 500px;
  }

  .tips{
    padding: 16px;
    font-size: 16px;
    color: #EE1C1C;
  }
</style>
