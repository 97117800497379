// 选择默认签章人
<template>
  <div class="checkbox">
     <el-checkbox v-bind="$attrs"  v-on="$listeners" true-label='1' false-label='0' >设为默认签章人</el-checkbox>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.checkbox{
  margin: 20px;
}
</style>
